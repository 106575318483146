import { mapGetters } from 'vuex'

export default {
    created: function () {

    },
    mounted() {
        setTimeout(this.updateIndexShowPost, 3000);
    },
    methods: {
        updateIndexShowPost() {
            const postOrderedIndex = this.getPostIndex;
            const postTemp = this.getPostsOrdered[postOrderedIndex];
            if (postTemp) {
                // console.log('setIndexShowPost', postOrderedIndex + 1);
                this.$store.dispatch('setIndexShowPost', postOrderedIndex + 1);
            }
        }
    },
    computed: {
        ...mapGetters('cache', ['getPostsOrdered']),
        ...mapGetters(['getIndexShowPost']),
        getPostIndex() {
            let url = this.$route.params.url;
            if (!url) {
                url = this.$route.path?.replace("/app/pages/", "");
            }
            const getPostsOrdered = this.getPostsOrdered;
            const postOrderedIndex = getPostsOrdered.findIndex(post => post.attributes.url === url);
            return postOrderedIndex;
        },

        post() {
            const postOrderedIndex = this.getPostIndex;
            let postTemp = this.getPostsOrdered[postOrderedIndex];
            if (!postTemp) {
                postTemp = {
                    attributes: {
                        title: '',
                        title2: '',
                        body: '',
                        image: '',
                        image2: '',
                        'created-at': '',
                        'update-at': ''

                    }
                }
            }

            return postTemp
        },
        nextPost() {
            const postOrderedIndex = this.getPostIndex;

            if (postOrderedIndex >= 0 && postOrderedIndex < this.getPostsOrdered.length - 1) {
                const postNext = this.getPostsOrdered[postOrderedIndex + 1];

                const url = postNext.attributes.body ? '/app/pages/post/' + postNext.attributes.url : '/app/pages/' + postNext.attributes.url;
                return url;
            }

            return "";
        }
    }
}
